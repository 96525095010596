var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 p-0 mx-0"},[_c('ul',{staticClass:"dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0",on:{"click":function($event){return _vm.onChangeNav(1)}}},[_c('a',{staticClass:"nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center",class:{active: _vm.isActive(1)},attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.product', {
                  class: 'w-25px h-25px d-inline-block ' + _vm.isActive(1) ? '' : 'opacity-30',
                  style: _vm.isActive(1) ? '' : 'opacity: 0.4;'
                }))}}),_c('span',{staticClass:"nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl"},[_vm._v(_vm._s(_vm.$t('general.product').toUpperCase())+" ")])])])]),_c('li',{staticClass:"nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0",on:{"click":function($event){return _vm.onChangeNav(2)}}},[_c('a',{staticClass:"nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center",class:{active: _vm.isActive(2)},attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.fabric', {
                  class: 'w-25px h-25px d-inline-block ' + _vm.isActive(2) ? '' : 'opacity-40',
                  style: _vm.isActive(2) ? '' : 'opacity: 0.4;'
                }))}}),_c('span',{staticClass:"nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl"},[_vm._v(_vm._s(_vm.$t('general.fabric').toUpperCase())+" ")])])])]),_c('li',{staticClass:"nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0",on:{"click":function($event){return _vm.onChangeNav(3)}}},[_c('a',{staticClass:"nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center",class:{active: _vm.isActive(3)},attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.accessory', {
                  class: 'w-25px h-25px d-inline-block ' + _vm.isActive(3) ? '' : 'opacity-40',
                  style: _vm.isActive(3) ? '' : 'opacity: 0.4;'
                }))}}),_c('span',{staticClass:"nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl"},[_vm._v(_vm._s(_vm.$t('general.accessory').toUpperCase())+" ")])])])]),_c('li',{staticClass:"nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mb-3 mb-lg-0",on:{"click":function($event){return _vm.onChangeNav(null)}}},[_c('a',{staticClass:"nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center justify-content-center items-center",class:{active: _vm.isActive(null)},attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon py-2 w-auto d-flex align-items-center justify-content-center items-center"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.all', {
                  class: 'w-25px h-25px d-inline-block ' + _vm.isActive(null) ? '' : 'opacity-40',
                  style: _vm.isActive(null) ? '' : 'opacity: 0.4;'
                }))}}),_c('span',{staticClass:"nav-text font-size-lg font-weight-bolder d-inline-block text-center pl-2 text-xl"},[_vm._v(_vm._s(_vm.$t('general.all').toUpperCase())+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }