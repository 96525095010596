<template>
  <div class="card card-custom">
    <div class="d-flex  py-2 justify-content-center">
       <span v-html="getIconByKey('icons.esc.message_send', {
                  class: 'w-70px h-70px d-inline-block object-fill cursor-pointer'
                })">
                </span>
    </div>
    <div class="card-body py-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <CustomMultiSelect
              :title="null"
              :placeholder="$t('esc.new_please_enter_email_language')"
              :required="true"
              :options="languageOptions"
              :model.sync="selectLanguage"
              :name="$t('esc.new_please_select')"
              :max="1"
              :not-list="true"
              object-key="language_translations.0.name"
          >
          </CustomMultiSelect>
        </div>
        <div class="col-12" v-for="(sendEmails, index) in sendEmail">
          <text-input
              :title="null"
              :placeholder="$t('esc.new_please_enter_email')"
              name="user_model"
              :model.sync="sendEmails['index']"
              :required="true"
          ></text-input>
        </div>
          <span @click="addNewEmail" v-html="getIconByKey('icons.esc.add', {
                  class: 'w-30px h-30px d-inline-block object-fill cursor-pointer'
                })">
                </span>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center">
      <button
          @click="closeModal"
          class="btn btn-sm btn-outline-danger btn-pill font-weight-bold cursor-pointer mr-5">{{ $t('esc.new_send_close').toUpperCase() }}
      </button>
      <button
          @click="onSubmit"
          :disabled="selectLanguage == null &&  sendEmail == null"
          class="btn btn-sm btn-outline-primary btn-pill font-weight-bold cursor-pointer">{{ $t('esc.new_send') }}
      </button>
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import TextInput from "@/assets/components/inputs/TextInput";
export default {
  name: "EscSendForm",
  components:{
    CustomMultiSelect,
    TextInput

  },
  props: {
    languages:{
      required: true,
    }
  },
  data() {
    return {
      languageOptions:{
        "1": {
          id: 1,
          language_translations: [
            {
              name: "Türkçe"
            }
          ],
        },
        "2": {
          id: 2,
          language_translations: [
            {
              name: "İNGİLİZCE"
            }
          ],
        },
        "3": {
          id: 3,
          language_translations: [
            {
              name: "İSPANYOLCA"
            }
          ],
        },
      },
      selectLanguage:null,
      sendEmail:[
        {
          'index': null
        }
      ],
    }
  },
  mounted() {

  },

  computed: {


  },

  methods: {
    isEmailValid(email){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email).toLowerCase();
    },
    addNewEmail(){
      this.sendEmail.push({
      })
    },
    onSubmit(){
      this.$emit("onSubmit", {
        emails: this.sendEmail,
        language: this.selectLanguage,
      });
      this.$modal.hide('open_to_send_form');
    },
    closeModal(){
      this.$modal.hide('open_to_send_form');
    }
  }
}
</script>

<style scoped>

</style>
